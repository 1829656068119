import { Get_BASE_URL } from "../config/baseUrl"
import { Get_ACCESS_KEY } from "./getAccessKey"



type RAW_SITES_DATA = RAW_SITE_DATA[]

type RAW_SITE_DATA = {
   GUID: string
   Id: number
   data: string
   googleTime: string
   insertTime: string
   siteUrl: string
}

export type SITES_DATA = SITE_DATA[]
export type GEN_SITES_DATA = {
   dataBySite: SITES_DATA
   extraBySite?: GetSiteExtraInfo
}

export type SITE_DATA = {
   GUID: string
   Id: number
   data: SITE_KEYWORDS_DATA
   googleTime: string
   insertTime: string
   siteUrl: string
}
export type SITE_KEYWORDS_DATA = KEY_WORD_OBJ[]

export type KEY_WORD_OBJ = {
   ctr: number
   // keywords: SITE_DATA_ARRAY_KEYWORDS
   keys: SITE_DATA_ARRAY_KEYWORDS
   clicks: number
   position: number
   impressions: number
}
export type SITE_DATA_ARRAY_KEYWORDS = string[]


type GetSiteExtraInfo = {
   debug: any[]
   keyWords: {
      keyWordHash: string
      rawString: string
      string: string
   }[]
   keyWordsCounter: {
      [str: string]: {
         Id: number
         googleTime: string
         position: number
      }[]
   }
   keyWordsLength: number
   startCountingDay: string
}
export async function Get_SITE_DATA(site: string | undefined, startDate: Date, endDate: Date, limit: number, token: string, otp: string) {
   return new Promise<GEN_SITES_DATA>(async (resolve, reject) => {
      if (typeof (site) === "undefined" || site.length === 0) {
         resolve({
            dataBySite: [],
            extraBySite: undefined
         })
         return
      }

      const BASE_URL: string = Get_BASE_URL()
      // http://127.0.0.1:12580/getDataBySiteUrl?siteUrl=https%3A%2F%2Fwww.wglass.net%2F&key=23423234&startDate=2021-07-20T16:00:00.000Z&limit=100

      try {
         if (!(startDate.valueOf() > 0)) { throw new Error("起始时间为无效") }
      } catch (error) {
         startDate = new Date("1971-01-01 00:00:00")
      }

      try {
         if (!(endDate.valueOf() > 0)) { throw new Error("结束时间为无效") }
      } catch (error) {
         endDate = new Date("2025-01-01 00:00:00")
      }
      // 2021-08-12T00:00:00.000Z

      const startDateText = startDate.toISOString().split("T")[0] + "T00:00:00.000Z"
      const endDateText = endDate.toISOString().split("T")[0] + "T00:00:00.000Z"
      let getDataBySiteUrl = function (token: string, otp: string, site: string, startDateText: string, endDateText: string, limit: number) {
         return new Promise<SITES_DATA>((resolve, reject) => {
            let xhr = new XMLHttpRequest()
            xhr.open("GET", `${BASE_URL}/getDataBySiteUrl?key=${token}&otp=${otp}&siteUrl=${site}&startDate=${startDateText}&endDate=${endDateText}&limit=${limit}`, true)
            xhr.send()
            xhr.onreadystatechange = () => {
               if (xhr.readyState === XMLHttpRequest.DONE) {
                  try {
                     const RAW_SITES_DATA: RAW_SITES_DATA = JSON.parse(xhr.responseText);
                     const RESPONSE: SITES_DATA = []
                     for (let RAW_SITE_DATA of RAW_SITES_DATA) {
                        const data: SITE_DATA = {
                           ...RAW_SITE_DATA,
                           data: JSON.parse(RAW_SITE_DATA.data)
                        }
                        RESPONSE.push(data)
                     }
                     resolve(RESPONSE)
                  } catch (error) {
                     reject()
                  }
               }
            }
         })
      }
      // debug: []
      // keyWords: [{ string: "switchable%20glass", rawString: "switchable glass", keyWordHash: "e67fc8741915b873" },…]
      // keyWordsCounter: { e67fc8741915b873: [{ Id: 5999, googleTime: "2021-08-23T00:00:00.000Z", position: 91 },…],… }
      // keyWordsLength: 133
      // startCountingDay: "2021-08-23T00:00:00.000Z"
      async function getSiteExtraInfo(token: string, SITE_URL: string) {
         return new Promise<GetSiteExtraInfo>((resolve, reject) => {
            const BASE_URL: string = Get_BASE_URL()
            let xhr = new XMLHttpRequest()
            xhr.open("GET", `${BASE_URL}/getSiteExtraInfo?key=${token}&siteUrl=${SITE_URL}`, true)
            xhr.send()
            xhr.onreadystatechange = () => {
               if (xhr.readyState === XMLHttpRequest.DONE) {
                  try {
                     let data = JSON.parse(xhr.responseText);
                     let arr = data;
                     console.debug("GetSiteExtraInfo", arr, data)
                     resolve(arr)
                  } catch (error) {
                     reject()
                  }
               }
            }
         })

      }

      const SITES_DATA_1 = await getDataBySiteUrl(token, otp, site, startDateText, endDateText, limit)
      const SITES_DATA_2 = await getSiteExtraInfo(token, site)
      resolve({
         dataBySite: SITES_DATA_1,
         extraBySite: SITES_DATA_2
      })

   })
}