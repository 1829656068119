import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { Card, Spin } from 'antd';
import { Table, DatePicker, Input } from 'antd';

const needOTP = false

type State = {

}
type KeysGetterInterface = {
    onFailed: () => void
    onSuccess: (token: string, otp: string) => void
}

class KeysGetter extends PureComponent<KeysGetterInterface, State> {
    constructor(props: KeysGetterInterface) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        const thisURL = new URL(document.location.href)
        const searchParams = thisURL.searchParams
        if (!searchParams.has("token")) {
            this.props.onFailed()
            return
        }
        if (!searchParams.has("otp") && needOTP) {
            this.props.onFailed()
            return
        }
        const token = String(searchParams.get("token"))
        const otp = searchParams.get("otp") || "000000"
        this.props.onSuccess(token, otp)
    }

    render() {
        return ""
    }
}


export default KeysGetter;
