import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { Card, Spin } from 'antd';
import { Table, DatePicker, Input } from 'antd';
import { RangePickerProps } from 'rc-picker';
import { Moment } from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import './App.css';
import { SiteSelector } from "./component/siteSelector"
import { Get_SITE_DATA, SITES_DATA, GEN_SITES_DATA } from "./function/getSiteData"
import { CalcWordHASH } from "./function/calcWordHASH"
import KeysGetter from "./controlPanel"
import { message } from 'antd';
import { ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { watch } from 'fs';

///DatePicker
type EventValue<DateType> = DateType | null;
type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;
///DatePicker end


type State = {
  loggedin: boolean,
  token?: string,
  otp?: string,
  GEN_DATA?: GEN_SITES_DATA,

  searchingText: string,
  currentSelectSiteData: SITES_DATA,
  currentSelectSiteUrl: string,
  dataLoading: boolean,
  searchingstartDate: Date,
  searchingstartDateISO: string,
  currentSearchingstartDateISO: string,
  searchingendDate: Date,
  searchingendDateISO: string,
  currentSearchingendDateISO: string,
  columns: Columns,
  dataSource: DataSource,
  inFirstPageCount: number,
  inFirstTenPageCount: number,
  /**总达标天数 */
  score: number | "?",
  /**当前选中日期范围内达标的关键词的总个数 */
  scoreInSelection: number,
  watchingKeywordsCount: number | "?",
  // leftServiceDay: number,
  rangePickerStartDate?: RangeValue<Moment>,
}
type Columns = {
  title: string,
  dataIndex: string,
  key: string,
  sorter?: (a: any, b: any) => number;
  fixed?: "left" | "right";
  width?: number,
  defaultSortOrder?: "ascend" | "descend" | undefined,
  sortOrder?: "ascend" | "descend" | undefined,
  render?: (text: any, record?: any, index?: any) => any,
}[]
type DataSource = {
  key: string;
  keyWords: string;
  d1?: string;
  [item: string]: any
}[]
type GOOGLE_DAY_OBJ = {
  displayText: string
  date: Date
  iso: string
  ts: number
}
class Analysis extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loggedin: false,
      searchingText: '',
      searchingstartDate: new Date("1971-01-01 00:00:00"),
      searchingstartDateISO: new Date("1971-01-01 00:00:00").toISOString(),
      currentSearchingstartDateISO: "0",
      searchingendDate: new Date("2025-01-01 00:00:00"),
      searchingendDateISO: new Date("2025-01-01 00:00:00").toISOString(),
      currentSearchingendDateISO: "0",
      currentSelectSiteData: [],
      currentSelectSiteUrl: '',
      dataLoading: false,
      columns: [],
      dataSource: [],
      inFirstPageCount: 0,
      inFirstTenPageCount: 0,
      score: "?",
      scoreInSelection: 0,
      watchingKeywordsCount: "?",
      // leftServiceDay: -1,
    }
  }
  leftServiceDayCalc(): number {
    const totalDay = 1000//后续改掉
    if (typeof (this.state.score) === "number") {
      return totalDay - this.state.score
    }
    return -1
  }
  isRawKeyWordWatching(text: string, inputGEN_DATA?: GEN_SITES_DATA): boolean {
    const thisStandardizedKeywordString = encodeURIComponent(text)
    const GEN_DATA = inputGEN_DATA || this.state.GEN_DATA
    const watchingKeyWordsString: Set<string> = new Set()
    if (GEN_DATA && GEN_DATA.extraBySite) {
      for (let keyWordInfo of GEN_DATA.extraBySite.keyWords) {
        const standardizedKeywordString = keyWordInfo.string
        watchingKeyWordsString.add(standardizedKeywordString)
      }
      if (watchingKeyWordsString.has(thisStandardizedKeywordString)) {
        return true
      }
    }
    return false
  }
  scoreCalc(data: GEN_SITES_DATA): {
    /**总达标天数的详细数据 */
    data: {
      [str: string]: number
    }
    /**总达标天数 */
    score: number
    /**当前选中日期范围内达标的关键词的总个数 */
    scoreInSelection: number
  } | false {
    const ExtraData = data.extraBySite

    console.log("scoreCalc", "data", data)
    /**SEO达标标准（关键词显示在首页的标准数量） */
    const daysGoal = 5
    /**几位之前算首页 */
    const complianceValue = 10
    if (ExtraData) {
      let daysCount: {
        [str: string]: number
      } = {}
      const keyWordsCounter = ExtraData.keyWordsCounter
      const keyWordsInfo = ExtraData.keyWords
      let scoreInSelection = 0
      {
        //计算 当前选中日期范围内达标的关键词的总个数
        const watchingKeyWordsString: Set<string> = new Set()
        const keyWordsMeetingTheStandard: Set<string> = new Set()

        for (let keyWordInfo of keyWordsInfo) {
          const StandardizedKeywordString = keyWordInfo.string
          watchingKeyWordsString.add(StandardizedKeywordString)
        }
        for (let siteData of data.dataBySite) {
          const keywordsData = siteData.data
          for (let keywordData of keywordsData) {
            const keywordString: string = encodeURIComponent(keywordData.keys[0] || "")
            if (keywordData.position < complianceValue) {
              /**是不是关键字之一 */
              if (watchingKeyWordsString.has(keywordString)) {
                keyWordsMeetingTheStandard.add(keywordString)
              }
            }

          }
        }
        scoreInSelection = keyWordsMeetingTheStandard.size
      }
      //计算 总达标天数
      for (let keywordHash in keyWordsCounter) {
        for (let dayInfo of keyWordsCounter[keywordHash]) {
          if (dayInfo.position <= complianceValue) {
            if (typeof (daysCount[dayInfo.googleTime]) === "undefined") {
              daysCount[dayInfo.googleTime] = 1
            } else {
              daysCount[dayInfo.googleTime]++
            }
          }
        }
      }


      let score = 0
      for (let day in daysCount) {
        if (daysCount[day] >= daysGoal) {
          score++
        }
      }
      return {
        score,
        data: daysCount,
        scoreInSelection
      }
    } else {
      message.error("extra返回值为空")
      return false
    }


  }
  async fetchData(site: string): Promise<void> {
    console.warn("fetchData", site)
    const GoogleTime = function (timeText: string): string {
      return new Date(new Date(timeText).valueOf() + 9 * 3600 * 1000).toLocaleString("zh-CN", { month: "numeric", day: "numeric", timeZone: "America/Los_Angeles" }).replaceAll("/", "-")
    }

    if (this.state.dataLoading) {
      return
    }
    console.log("site", site)
    this.setState({
      currentSelectSiteUrl: site,
      dataLoading: true,
    })

    let limit = 7

    {
      if (this.state.searchingstartDate.valueOf() > 31507200000 &&
        this.state.searchingendDate.valueOf() < 1735660800000) {
        const tss = this.state.searchingendDate.valueOf() - this.state.searchingstartDate.valueOf()
        const days = (tss / 1000 / 3600 / 24) + 1
        console.warn("days", days)
        limit = days
      }
    }

    console.log("Get_SITE_DATA", "searchingstartDate", this.state.searchingstartDate.toISOString())
    console.log("Get_SITE_DATA", "searchingendDate", this.state.searchingendDate.toISOString())
    const GEN_DATA: GEN_SITES_DATA = await Get_SITE_DATA(site,
      this.state.searchingstartDate,
      this.state.searchingendDate,
      limit, String(this.state.token), String(this.state.otp))
    const DATA = GEN_DATA.dataBySite

    const score = this.scoreCalc(GEN_DATA)
    if (GEN_DATA.extraBySite && score) {
      this.setState({
        scoreInSelection: score.scoreInSelection,
        score: score.score,
        watchingKeywordsCount: GEN_DATA.extraBySite?.keyWords.length
      })
    }

    console.log("score", score)
    let keyWordsSet: Set<string> = new Set([]);
    let days: GOOGLE_DAY_OBJ[] = []
    let inFirstPageCount: number = 0
    let inFirstTenPageCount: number = 0
    let maxDateTS4Counter: number = 0
    for (let dayData of DATA) {
      days.push({
        iso: dayData.googleTime,
        // displayText: new Date(dayData.googleTime).toLocaleString("zh-CN", { month: "numeric", day: "numeric", timeZone: "PST" }).replaceAll("/", "-"),
        displayText: GoogleTime(dayData.googleTime),
        date: new Date(dayData.googleTime),
        ts: new Date(dayData.googleTime).valueOf()
      })
      let isLastestDay = false
      try {
        if (new Date(dayData.googleTime).valueOf() > maxDateTS4Counter) {
          maxDateTS4Counter = new Date(dayData.googleTime).valueOf()
          isLastestDay = true
          inFirstTenPageCount = 0
          inFirstPageCount = 0
        }
      } catch (error) {
        console.error(error)
      }
      for (let dayItem of dayData.data) {
        for (let keyword of dayItem.keys) {
          if (isLastestDay) {
            // inFirstPageCount
            if (dayItem.position <= 10) {
              inFirstPageCount++
            }
            // inFirstTenPageCount
            if (dayItem.position <= 100) {
              inFirstTenPageCount++
            }
          }
          // console.log("keyWord.dayItem", dayItem)
          keyWordsSet.add(keyword)
        }
      }
    }
    var keyWords = [...keyWordsSet]
    let maxDayTs = 0
    days.sort(function (a, b) {
      maxDayTs = Math.max(maxDayTs, a.ts, b.ts)
      return a.ts - b.ts;
    });

    /////////////////////////////////////////////////
    const columns: Columns = [
      {
        title: '关键词',
        dataIndex: 'keyWords',
        key: 'keyWord',
        fixed: 'left',
        width: 400,
        render: (text) => <div style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: 384,
          overflow: "hidden"
        }}>{text}</div>,
      }, {
        title: '监控',
        dataIndex: 'watching',
        key: 'watching',
        fixed: 'left',
        width: 80,
        // @ts-ignore
        filters: [
          {
            text: '未在监控',
            value: false,
          },
          {
            text: '正在监控',
            value: true,
          },
        ],
        onFilter: (value: boolean, record: any) => record.watching === value,
        render: (text: boolean) => <div style={{ textAlign: "center" }}>{text ? "是" : "否"}</div>,
      },
    ];
    for (let day of days) {
      columns.push({
        title: day.displayText,
        dataIndex: day.displayText,
        key: String(day.ts),
        defaultSortOrder: day.ts === maxDayTs ? "ascend" : undefined,
        sorter: (a, b) => { return Number(a[day.displayText]) - Number(b[day.displayText]) },
        render: (text, item) => <div style={{ background: this.calcColor(parseInt(text)), color: "white" }}>
          {text}
        </div>,
      })
    }
    /////////
    const dataSource: DataSource = [];

    for (let keyWord of keyWords) {
      let thisKeyWordLineData: any = {
        key: CalcWordHASH(keyWord),
        keyWords: keyWord,
      }
      for (let dayData of DATA) {
        // let thisDisplayText = new Date(dayData.googleTime).toLocaleString("zh-CN", { month: "numeric", day: "numeric", timeZone: "PST" }).replaceAll("/", "-");
        let thisDisplayText = GoogleTime(dayData.googleTime);
        let hit = false
        for (let dayItem of dayData.data) {
          if (hit) { continue }
          for (let thiskey of dayItem.keys) {
            if (keyWord === thiskey) {
              thisKeyWordLineData[thisDisplayText] = dayItem.position.toFixed(1)
              hit = true
              continue
            }
          }
        }
        if (!hit) {
          thisKeyWordLineData[thisDisplayText] = 100
        }
      }
      const isWatching = this.isRawKeyWordWatching(keyWord, GEN_DATA)
      thisKeyWordLineData["watching"] = isWatching
      dataSource.push(thisKeyWordLineData)
    }

    //////////////////////
    this.setState({
      GEN_DATA,
      currentSelectSiteData: DATA,
      columns,
      dataSource,
      dataLoading: false,
      currentSearchingstartDateISO: this.state.searchingstartDate.toISOString(),
      currentSearchingendDateISO: this.state.searchingendDate.toISOString(),
      inFirstPageCount,
      inFirstTenPageCount,
    })

  }
  calcColor = function (pos: number) {
    if (pos < 10) {
      // return "#75ff79"
      return "#1890FF"
    } else if (pos < 20) {
      // return "#91E478"
      return "#682cfb"//电紫色
    } else if (pos < 30) {
      // return "#B9BC77"
      return "#4f1970"//联邦快递紫色
    } else if (pos < 40) {
      // return "#D6A176"
      return "#b70000"
    } else if (pos < 100) {
      // return "#FF7875"
      return "#4c0000"//暗红
    } else {
      // return "#737373"
      return "#1c1c1c"
    }
  }
  render() {

    if (this.state.currentSelectSiteUrl.length > 0 && !this.state.dataLoading) {
      if (this.state.currentSearchingstartDateISO != this.state.searchingstartDateISO ||
        this.state.currentSearchingendDateISO != this.state.searchingendDateISO) {
        setTimeout(() => {
          this.fetchData(this.state.currentSelectSiteUrl)
        }, 0);
      }
    }
    const iconStats = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsSAAALEgHS3X78AAABIElEQVRoge2a4Q2CMBBGPw0DMIVrwBz+cgQ3sCOwgY6CazgFG9Q0tokSC7S03hXvJeQgtMAr0MsFdlprbIH9m8OpZB8ncgZwtbFIKnvR9SjGUAcMRAdgSDlg1YI2SzESl4D2KuG5k4o47gB6z74WQJPhnFlE+onRVrlE9gvaFIGIcGMzIjle9hCS5R5qkWS5h1rEsTr3cBFZnXtk1uKGiHBDRLjhm35Jq70YfCKk1V4McwmRpNqLYU6EpNqLQWYtbogIN0SEGyLCDRHhhohwQ0S4ISLc+JvPCu1EhdgS9wkSaSLK2V/1+cAn0gUcoxvF3H2+Y36q0Vor/ULZ7eKW8R2ZelY5Mrg75UQONq5+VgkwMjcncrTxUaKEWdnGj2cAnh5xxUNUIF+gAAAAAElFTkSuQmCC"
    const iconLight = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsSAAALEgHS3X78AAADS0lEQVRoge1aQa7TMBB9HxALNgSJLfweIeyRfjkB5QKoC9iXG/QIOUL/khW/JyBIHCDskci/AOSvWRj5MyNNLccZp5OqqvqkUdN0Ys/zjO0ZpxfOOZwCHhhyKDN0CwBzy/Gz8og3rAXQKA1s6ZnConMYe+QpgCuFcd5zl4b93sOKSAfgB10PeYR/r436voelR9iw1wN6zwN9E1iuWjMAvrFbha4Pw4cAfh+SSElxP2YEwzBrKAy1WJH+ZlDfExmQ2v3HRqE7J73W9aMlnflAWxtqoVb0qyKyFCb1kVn0GN+QITVdh2h7CDGJzjlXWhEJyXwQ9wvn3I34rVOMNnutCwbIt+V/f+Gc+5NDIocIk/kivpfBKK+FMRrxulXgPX7+ivpT25dDxMtj4Qke0TZn5Ho81EXIZMnYzpt9Ow6kFGRUk3tfj4BCKGsijiCznpqIDKmsGFbKSgxSlqfHemOU+5VSj/GK/DKjZXCVCJl2Qm+wLMQiEtq3ouW+pesoER5tuSf4h16KhhhTkWDh8OUB/R7ZTHeIyOx3DeATgC2AO0rs3vp8TORcHt9G5Fy54LyOc7W/9LklG18BqGSbj4IOKqFQUkO34juoupsaDQ0iF2kfAfxM9RkSkWhIQhyKCIRHkiRgXFhZIifVn4TIgoorjSwT7bAn1N7PIcKNpmrylhaKIdz1hC2jEO2pkJojIbjj1PlVEzlF4RL0IqLfBx6sFNkd5NbsHS3L7wDcKJ/JJeJr/190/Yy8N2hkKrTmtLd8Fve4dl4pjRqDNT2zpYF7T56paA7GEeyoS9rNu2AXvYzs7kM1N0tONlBG2v8a2dXrMI0KSUjwIYG//0TocVXXKjPUHCKNMJTvFZR/VcG5wKaPyIyUU0kjN8wNagorLRF54DBL6M3IW0UfkRwpM8rTISLhAYY2ZGFBJCTTkftjesvEMdI8CJfR5cE+RBA5SamVIzoTBZQzOMDYmwjLOljpUtXdwu2isjjAsCICMmYjTOzTq4X3UpM6S6Z4hzi0k9f0QuiN5auFY03js3Emcmw4GSI59UguDvpPhCmIXFNNMQR10aTB+S8cx4YpQqtU/Puhsw4tyxQllkelYHoQbu2RNuNs+DzZYziNyQ7gH6qi2adKWgIjAAAAAElFTkSuQmCC"
    const iconHourglass = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsSAAALEgHS3X78AAACeklEQVRoge2a0U3DMBCGfxCPSO0IGaEbNCMwQpmAjpANKBuEDToAD2GDsEFHyAMvPBlZnKXDvVLfxUah5JcsJU1yd5/POSdOr5xzuARdXwTFDDJBSSAbAG7ibZOSkfoPJOAoRk3V6gCsAbzSdknVzFdSx94YgvEQTWGQhkCSNZffqWkGoXGcu0yb7z0LSGV1VtLHvwQJc8c62s+p3upDA9Kz7btoP5c6si35/Fl+Zk9sS+fc4L7U0TWty6c92ezI4qCITQUSB14751YZQby9mu23JUEq5qinLO0yQOzIVs9+q0qC+NYIvdYJwaUqdAjPdqONywKCqOdaIZBU7YVre0tMVhB+4zvKiB8KG+fcIQFioHOrKJsD2VbHZH1EGeg94Y3211Qq/US2AnAP4JkdB20/07GKWs/mDH+8Jtt6GTPCM7MXejwMGV6F6oTzzbGMBQltKwS3E0CkCrfNEUMOiEYI7kDjPwapTtxD6iqVE2QplN2OAg7nxCD8d+la8/AaA8FL8CEK9BwIP84z1FthrCBx3T/l/ByI1CmqR5MxIFtFD6aASDDqAmAZUmEiTJm8UkEstr817YS4BbCg7Y158pLlbYWl0AX5SpcyI/H7yLmmyUhopvcRTUZqlo02YyZiBdsLzTq0FiRob40yQdx2UZC3zPdGrIE9bBYBCTpYIyzpwwJSYvVktI+LWfu1fB+RVNF61DI65vc/aPtOGPMD3dyjh2sOkB2Ah4TzTp3zCOBJPQFGGju0UiHO6YFsmWXJSEVD5DYTRJC39QLg3bKIbfkY+ptK/hgqDa32xEeY34YA+ZRiOXpEkkBKf3rOoaMY538HTU0zyKQE4BPziDPn0vlIsgAAAABJRU5ErkJggg=="
    const columns: Columns = this.state.columns
    const dataSource: DataSource = this.state.dataSource;
    let realDataSource: DataSource = [];
    if (this.state.searchingText.length > 0) {
      for (let item of dataSource) {
        if (item.keyWords.toLocaleLowerCase().indexOf(this.state.searchingText.toLocaleLowerCase()) !== -1) {
          realDataSource.push(item)
        }
      }
    } else {
      realDataSource = dataSource
    }

    let topBarCardColStyle: React.CSSProperties = { padding: 8, textAlign: "left" }
    let topBarCardAvatar: React.CSSProperties = { filter: "invert(1)" }
    let today = new Date().toLocaleString("zh-cn", { year: "numeric", month: "numeric", day: "numeric" })

    const disabledDate = (current: Moment) => {
      let dates = this.state.rangePickerStartDate || []
      if (!dates || dates.length === 0) {
        return false;
      }
      const tooLate = !!(dates[0] && (current.diff(dates[0], 'days') > 7));
      const tooEarly = !!(dates[1] && (dates[1].diff(current, 'days') > 7));
      return tooEarly || tooLate;
    };

    return (
      <div className="App" style={{ overflow: "hidden" }}>
        <KeysGetter
          onFailed={() => {
            message.error("未登录", 0);
          }}
          onSuccess={(token, otp) => {
            this.setState({
              // loggedin: true,
              token,
              otp,
            })
          }}
        />
        <Spin
          tip="身份验证"
          size={"large"}
          //  indicator={<ExclamationCircleOutlined />}
          indicator={<SyncOutlined spin />}
          spinning={!this.state.loggedin}>
          <Spin
            tip="下载数据"
            spinning={this.state.dataLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            delay={200}>
            <Row>
              <Col span={6} style={topBarCardColStyle}>
                <Card style={{ marginTop: 16 }} >
                  <Card.Meta
                    avatar={<img src={iconStats} alt={"排名"} style={topBarCardAvatar} />}
                    title={`排名第一页：${this.state.inFirstPageCount}`}
                    description={`统计日期:${today}`}
                  />
                </Card>
              </Col>
              <Col span={6} style={topBarCardColStyle}>
                <Card style={{ marginTop: 16 }} >
                  <Card.Meta
                    avatar={<img src={iconStats} alt={"排名"} style={topBarCardAvatar} />}
                    title={`排名前十页：${this.state.inFirstTenPageCount}`}
                    description={`统计日期:${today}`}
                  />
                </Card>
              </Col>
              <Col span={6} style={topBarCardColStyle}>
                <Card style={{ marginTop: 16 }} >
                  <Card.Meta
                    avatar={<img src={iconLight} alt={"统计"} style={topBarCardAvatar} />}
                    title={`达标关键词个数：${this.state.scoreInSelection}/${this.state.watchingKeywordsCount}`}
                    description={`统计日期:${today}`}
                  />
                </Card>
              </Col>
              <Col span={6} style={topBarCardColStyle}>
                <Card style={{ marginTop: 16 }} >
                  <Card.Meta
                    avatar={<img src={iconHourglass} alt={"续费"} style={topBarCardAvatar} />}
                    title={`剩余服务天数：${this.leftServiceDayCalc()}`}
                    description={"请提前90天联系客服续费"}
                  />
                </Card>
              </Col>
            </Row>

            <div style={{ height: 50, width: "100%", display: "flex", alignItems: "center", paddingLeft: 10, paddingRight: 10 }}>
              <div style={{ width: "50%", height: "100%", display: "inline-block", textAlign: "left" }}>
                <div style={{ width: "50%", height: "100%", display: "inline-block", textAlign: "left", verticalAlign: "top" }}>
                  <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "100%", height: 32, }}>
                      <DatePicker.RangePicker
                        // disabledDate={disabledDate}
                        // onCalendarChange={val => this.setState({ rangePickerStartDate: val })}
                        // onOpenChange={(open) => { if (!open) { this.setState({ rangePickerStartDate: undefined }) } }}

                        onChange={(event) => {
                          if (event !== null) {
                            if (event[0] !== null) {
                              var searchingstartDate = new Date(event[0].toISOString())
                              this.setState({
                                searchingstartDate,
                                searchingstartDateISO: searchingstartDate.toISOString()
                              })
                            } else {
                              message.info('起始时间已重置');
                              this.setState({
                                searchingstartDate: new Date("1971-01-01 00:00:00"),
                                searchingstartDateISO: new Date("1971-01-01 00:00:00").toISOString()

                              })
                            }
                            if (event[1] !== null) {
                              var searchingendDate = new Date(event[1].toISOString())
                              this.setState({
                                searchingendDate,
                                searchingendDateISO: searchingendDate.toISOString()
                              })
                            } else {
                              message.info('结束时间已重置');
                              this.setState({
                                searchingendDate: new Date("2025-01-01 00:00:00"),
                                searchingendDateISO: new Date("2025-01-01 00:00:00").toISOString()
                              })
                            }
                          }
                        }} />
                    </div>
                  </div>
                </div>

                <div style={{ width: "50%", height: "100%", display: "inline-block", textAlign: "right", verticalAlign: "top" }}>

                  <div style={{
                    height: "100%",
                    width: "calc(100% - 20px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }} >
                    <SiteSelector
                      onLoggedin={() => {
                        this.setState({
                          loggedin: true
                        })
                      }}
                      token={this.state.token}
                      otp={this.state.otp}
                      onChange={(value) => {
                        this.fetchData(value)
                      }}
                    />
                  </div>


                </div>
              </div>
              <div style={{ width: "50%", display: "inline-block" }}>
                <Input.Search placeholder={"关键字筛选"} onChange={(event) => {
                  this.setState({ searchingText: event.target.value })
                }} enterButton />
              </div>
            </div>
            {(() => {
              if (!this.state.dataLoading) {
                return <Table
                  size={"small"}
                  dataSource={realDataSource}
                  columns={columns}
                  pagination={{
                    responsive: true,
                    defaultPageSize: 10,
                    pageSizeOptions: ["10", "50", "100", "1000"]
                  }}
                  style={{}} />
              }
            })()}
          </Spin>
        </Spin>
      </div >
    );

  }
}


export default Analysis;
