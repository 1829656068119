import React, { PureComponent } from 'react';
import { Select } from 'antd';
import { message } from 'antd';

import { GetSites } from "../function/getSites"

type State = {
  sitesList: string[],
  defaultValue?: string,
  fetchingData?: boolean,
}

interface SelectInterface {
  token?: string;
  otp?: string;
  onChange: (value: string) => void;
  onLoggedin: () => void;
}


export class SiteSelector extends PureComponent<SelectInterface, State> {
  constructor(props: SelectInterface) {
    super(props);
    this.state = {
      sitesList: []
    }
  }

  async componentDidMount() {
    if (typeof (this.props.token) === "string" && typeof (this.props.otp) === "string") {
      if (this.state.sitesList.length === 0) {
        if (!this.state.fetchingData) {
          try {
            const TOKEN: string = this.props.token || ""
            const OTP: string = this.props.otp || ""
            console.log("TOKEN", TOKEN, "OTP", OTP)
            this.setState({
              fetchingData: true
            })
            let sites = await GetSites(TOKEN, OTP)
            // debugger
            this.props.onLoggedin()
            if (sites.length === 1) {
              setTimeout(() => {
                this.props.onChange(sites[0])
                this.setState({ defaultValue: sites[0], })
              }, 0);
            }
            this.setState({ sitesList: sites, })
          } catch (error) {
            message.error("登录出错")
          }

        }
      }
    }

  }
  componentDidUpdate = this.componentDidMount


  render() {
    return (
      // <div className="App" style={{ overflow: "hidden" }}>

      <Select
        showSearch
        style={{ width: "100%", marginLeft: 10 }}
        placeholder={"选择站点"}
        optionFilterProp="children"
        onChange={this.props.onChange}
        value={this.state.defaultValue}
        // onFocus={onFocus}
        // onBlur={onBlur}
        // onSearch={onSearch}
        filterOption={(input, option) => {
          if (typeof (option) === "object") {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          return false
        }}
      >
        {Array.from(this.state.sitesList, (item, id) => {
          return <Select.Option key={String(item)} value={String(item)}>{item}</Select.Option>
        })}
      </Select>

      // </div >
    );

  }
}

