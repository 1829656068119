import * as MD5 from "js-md5"


function SafeKeyword(keyword: string) {
    return encodeURI(keyword)
}
export function CalcWordHASH(input: string): string {
    let safeWord = SafeKeyword(input)
    var hash = MD5.create();
    hash.update(safeWord);
    const md5 = hash.hex();
    const hashResult = md5.substring(8, 24)
    return hashResult
}